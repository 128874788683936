import { Link } from 'gatsby';
import React from 'react';
import { CircleCheck } from 'tabler-icons-react';
import { Spoiler, Table, List, ThemeIcon } from '@mantine/core';

const Nondeparments1 = () => {
  const services = [
    {
      name: "	Chief Medical Director's Office",
    },
    {
      name: '	Chairman Medical Advisory Commitees Office',
    },
    { name: "	Director of Administration's Office " },
    {
      name: '	Audit Department ',
    },

    {
      name: '	Clinical Services And Training Division',
    },
    {
      name: '	Corporate Services',
    },
    { name: '	Corporate Social Investment' },
    {
      name: '	Engineering Services',
    },
    {
      name: 'Finance And Accounts',
    },
    {
      name: '	General Administration Division',
    },
    {
      name: '	Hospital Facilities Management',
    },
    {
      name: '	Human Resources Management Division',
    },
    {
        name: '	Legal Services Department',
      },
      { name: '	Printing Unit' },
      {
        name: 'Procurement Department',
      },
      {
        name: 'Protocol Unit',
      },
      {
        name: '	Security Unit',
      },
      {
        name: '	Servicom Unit',
      },
      {
        name: '	Staff Development, Training And Welfare',
      },
      {
        name: '	Stores Department',
      },
  ];
  
  return (
    <section className='faq-one'>
      <div className='container'>
        <div className='row no-gutters'>
        <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {services.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
        </div>
      </div>
    </section>
  );
};

export default Nondeparments1;
