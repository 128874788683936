import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import NavOne from '../components/NavOne';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import Nondeparments1 from '../components/Nondepartments1';

const FaqPage = () => {
  // useEffect(() => {
  //   navigate('/inprogress/departments');
  // }, []);
  return (
    <Layout pageTitle='Non-Clinical Departments | LUTH'>
      <NavOne />
      <PageHeader title='Non-Clinical Departments' />
      <Nondeparments1 />
      <Footer />
    </Layout>
  );
};

export default FaqPage;
